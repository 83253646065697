import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import Slide from '@material-ui/core/Slide';
import axios from '../utilities/axios';
import { enqueueSnackbar } from './notifier';
import { closeModal } from './modals';

const initialState = {
  allExams: {
    data: [],
    status: 'idle',
    error: null,
  },
  exam: {
    data: {},
    status: 'idle',
    error: null,
  },
};

export const fetchAllExams = createAsyncThunk('exams/fetchAllExams', async () => {
  let data;
  try {
    const response = await axios.get('/instructor/content-exams');
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const fetchExamById = createAsyncThunk('exams/fetchExamById', async (examId) => {
  let data;
  try {
    const response = await axios.get(`/instructor/content-exams/${examId}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const addNewExam = createAsyncThunk('exams/addNewExam', async (query, thunkAPI) => {
  const { values } = query;
  let data;
  try {
    const response = await axios.post(`/instructor/content-exams`, values);
    data = await response.data;
    if ((response.status = 200)) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: data.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            TransitionComponent: Slide,
          },
        })
      );
      thunkAPI.dispatch(closeModal('add-exam-modal'));
      return data.payload;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const addCorrectionToStudent = createAsyncThunk(
  'exams/addCorrectionToStudent',
  async (query, thunkAPI) => {
    const { examId, studentId, values } = query;
    let data;
    try {
      const response = await axios.post(
        `/instructor/content-exams/${examId}/student/${studentId}/solution`,
        values
      );
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('exam-test-modal'));
        window.location = `/examens/${examId}`;
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const deleteCorrectionFromStudent = createAsyncThunk(
  'exams/deleteCorrectionFromStudent',
  async (query, thunkAPI) => {
    const { contentFileId, examId } = query;
    let data;
    try {
      const response = await axios.delete(`/instructor/file/${contentFileId}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              TransitionComponent: Slide,
            },
          })
        );
        thunkAPI.dispatch(closeModal('exam-test-modal'));
        window.location = `/examens/${examId}`;
        return data.payload;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'exams',
  initialState,
  reducers: {
    cleanExam: (state, action) => {
      state.exam = {
        data: {},
        status: 'idle',
        error: null,
      };
    },
  },
  extraReducers: {
    [fetchAllExams.pending]: (state, action) => {
      state.allExams.status = 'loading';
    },
    [fetchAllExams.fulfilled]: (state, action) => {
      state.allExams.status = 'succeeded';
      state.allExams.data = action.payload;
    },
    [fetchAllExams.rejected]: (state, action) => {
      state.allExams.status = 'failed';
      state.allExams.error = action.payload;
    },
    [fetchExamById.pending]: (state, action) => {
      state.exam.status = 'loading';
    },
    [fetchExamById.fulfilled]: (state, action) => {
      state.exam.status = 'succeeded';
      state.exam.data = action.payload;
    },
    [fetchExamById.rejected]: (state, action) => {
      state.exam.status = 'failed';
      state.exam.data = {};
      state.exam.error = action.payload;
    },
    [addNewExam.fulfilled]: (state, action) => {
      state.allExams.data.exams.push(action.payload);
      state.allExams.data.nbHomework.push({ id: action.payload.id, nbHomeworks: 0 });
    },
  },
});

export const reducer = slice.reducer;

export const cleanExam = () => (dispatch) => {
  dispatch(slice.actions.cleanExam());
};

export default slice;
