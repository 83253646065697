import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = (props) => {
  return (
    <div className="loading">
      <CircularProgress />
    </div>
  );
};

Loading.propTypes = {};

export default Loading;
